import React from "react";
import { Stack, Heading, Center } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { Markup } from "interweave";
import { capitalize } from "lodash";

import Authors from "./Authors";
import TextBlock from "./TextBlock";

const initials = (name) => {
  return name.match(/\b(\w)/g).join("");
};

const authorsString = (authors) => {
  if (!authors || authors.length === 0) return "";

  if (authors.length > 8) {
    const lastAuthor = authors[authors.length - 1];
    return `${authors
      .slice(0, 6)
      .map((author) => `${author.lastName} ${initials(author.firstName)}`)
      .join(", ")}, et al., ${lastAuthor.lastName} ${initials(
      lastAuthor.firstName
    )}`;
  }
  return authors
    .map((author) => `${author.lastName} ${initials(author.firstName)}`)
    .join(", ");
};

const Correction = (props) => {
  const { article, correctionId } = props;

  const { versions, corrections } = article;
  const version = versions.filter((v) => v.decision === "publish")[0];
  const correction = corrections.filter((c) =>
    c.doi.includes(correctionId.split("-")[2])
  );

  const {
    correctionType,
    doi: correctionDoi,
    description,
    date,
  } = correction[0];

  const { title, authors } = version;

  const year = new Date(date).getFullYear();
  const copyright = `\u00a9 ${year} by the authors. This is an open-access article distributed under the terms of the Creative Commons Attribution 4.0 International (CC BY 4.0) 
  License, which permits unrestricted use, distribution, and reproduction in any medium, provided the original author and source are credited.`;

  const titleWithoutP = `${capitalize(correctionType)}: ${title
    .replace("<p>", "")
    .replace("</p>", "")}`;

  const citationString = `${authorsString(
    authors
  )}. ${year}. ${titleWithoutP}. microPublication Biology. <a href="https://doi.org/${correctionDoi}" target="_blank" rel="noopener noreferrer">${correctionDoi}</a>.`;

  return (
    <Center>
      <Stack paddingTop="100px" w="50%">
        <Heading fontFamily="Noto sans, sans-serif">
          <Markup content={`${capitalize(correctionType)}: ${title}`} />
        </Heading>
        <Authors authors={authors} />

        <TextBlock label="Description" text={description} isHtml />
        <TextBlock label="Copyright" text={copyright} />
        <TextBlock label="Citation" text={citationString} isHtml />
      </Stack>
    </Center>
  );
};

Correction.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        credit: PropTypes.arrayOf(PropTypes.string),
        email: PropTypes.string,
        correspondingAuthor: PropTypes.bool,
        equalContribution: PropTypes.bool,
        affiliations: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    abstract: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
    imageTitle: PropTypes.string,
    imageCaption: PropTypes.string,
    description: PropTypes.string,
    methods: PropTypes.string,
    reagents: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.shape({})),
    acknowledgements: PropTypes.string,
    funding: PropTypes.string,
    reviewers: PropTypes.arrayOf(PropTypes.shape({})),
    history: PropTypes.shape({}),
    doi: PropTypes.string,
  }).isRequired,
};

export default Correction;
